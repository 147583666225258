<template>
  <v-dialog
    v-model="dialog"
    keydown
    max-width="800px"
    transition="dialog-bottom-transition"
  >
    <v-container
      fill-height
      fluid
      style="background-color: white;"
    >
      <v-card
        class="elevation-0 general-form"
        min-height="230px"
        width="100%"
      >
        <v-toolbar
          flat
          dense
          class="mb-4"
        >
          <v-toolbar-title>{{ $t('actions|add_new_professional') }}</v-toolbar-title>
          <div
            style="position: absolute; top: 0px; right: 7px"
          >
            <v-btn
              icon
              @click="dialog = false"
              @mouseover="hoverCloseButtonIcon = true"
              @mouseleave="hoverCloseButtonIcon = false"
            >
              <v-icon
                v-if="hoverCloseButtonIcon"
                small
              >
                mdi-close
              </v-icon>
              <v-icon
                v-else
              >
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-expand-transition>
          <div>
            <v-text-field
              v-model="userEmail"
              :label="$t('common|email')"
              :error-messages="emailErrors"
              outlined
              dense
              :hint="$t('actions|add_pro_with_existing_lavvira_account')"
              persistent-hint
              class="mx-4 mt-1"
              append-icon="mdi-close"
              @click:append="clearField()"
              @input="$v.userEmail.$touch()"
              @blur="$v.userEmail.$touch()"
            />
            <v-textarea
              v-model="additionalMessage"
              counter
              outlined
              :rules="rules"
              :label="$t('cases|message') + '...'"
              value=""
              class="mx-4 mt-3"
            />
          </div>
        </v-expand-transition>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            class="mb-2"
            rounded
            dense
            small
            :disabled="emailErrors.length > 0 || userEmail.length === 0 || additionalMessage.length > 125"
            min-width="150"
            @click="sendInvitation(userEmail)"
          >
            {{ $t('actions|submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { EventBus } from '@/utils/EventBus'
import ClientActions from '@/pages/Clients/ClientMixins/ClientActions'

export default {
  components: {},
  validations: {
    userEmail: { required, email }
  },
  mixins: [ClientActions, validationMixin],
  data () {
    return {
      dialog: false,
      clientType: null,
      actionType: 'create',
      clientTypes: [
        {
          title: this.$t('clients|individual'),
          value: 'individual'
        },
        {
          title: this.$t('clients|company'),
          value: 'company'
        }
      ],
      loading: false,
      error: null,
      hoverCloseButtonIcon: false,
      userEmail: '',
      additionalMessage: '',
      rules: [v => v.length <= 125 || 'Max 125 characters']
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    }),
    emailErrors () {
      const errors = []
      if (this.userEmail !== null && this.userEmail.length > 0) {
        if (!this.$v.userEmail.$dirty) return errors
        !this.$v.userEmail.email && errors.push(this.$t('warning|valid_email'))
        !this.$v.userEmail.required && errors.push(this.$t('warning|email_required'))
      }
      return errors
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    }
  },
  created () {
    EventBus.$on('dialog-add-client', this.onAddClientEvent)
  },
  beforeDestroy () {
    EventBus.$off('dialog-add-client', this.onAddClientEvent)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      sendInvitationToProfessionalUser: 'account/sendInvitationToProfessionalUser'
    }),
    onAddClientEvent (event) {
      this.dialog = true
    },
    reset () {
      if (this.$refs.clientForm) this.$refs.clientForm.reset()
      this.clientType = null
      this.userEmail = ''
      this.additionalMessage = ''
    },
    getBack () {
      this.$refs.clientForm.reset()
    },
    clearField () {
      this.userEmail = ''
    },
    async sendInvitation (email) {
      const alreadyConnectedAcc = this.account.myProfessionals.accounts.find(acc => acc.email === email) ||
      this.account.myProfessionals.company.find(c => c.companyData.email === email) ||
      this.account.myProfessionals.groups.find(g => g.groupEmail === email)
      let filteredPendingInvitations = this.account.myProfessionals.pending.filter(inv => {
        return inv.item_id.email === email && !inv.declined
      })
      if (alreadyConnectedAcc) {
        this.addToast({
          title: this.$t('warning|already_connected with_professional'),
          color: 'white',
          snackbarColor: 'warning'
        })
      } else if (filteredPendingInvitations.length >= 1) {
        this.addToast({
          title: this.$t('expressions|already_sent_invitation_to_user'),
          color: 'white',
          snackbarColor: 'warning'
        })
      } else {
        try {
          const _id = this.account._id
          const payload = {
            invitationEmail: email,
            invitationType: 'from client',
            additionalMessage: this.additionalMessage,
            senderType: this.account.accountType
          }
          await this.sendInvitationToProfessionalUser({ _id, payload })
          this.reset()
          this.dialog = false
        } catch (error) {
          console.error(error, 'invitation error')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.general-form {
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
