<template>
  <v-container
    v-if="isQuickstart && !isNotes && !isPinboard"
    fluid
    fill-height
  >
    <v-row
      v-if="account && account.accountType === 'lawyer'"
      align="center"
      justify="start"
    >
      <v-col
        v-for="(button, index) in buttons"
        :key="index"
        :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
        :class="$vuetify.breakpoint.mobile ? 'ml-n6' : ''"
      >
        <v-tooltip
          :top="button.text === $t('clients|client') || button.text === $t('cases|case')"
          :bottom="button.text === $t('members|member')"
          :color="button.text === $t('members|member') ? 'info' : 'warning'"
          max-width="250"
        >
          <template v-slot:activator="{ on }">
            <div v-on="disabledRule(button) ? on : ''">
              <v-btn
                color="primary"
                :width="$vuetify.breakpoint.mobile ? '100%' : '90%'"
                min-height="50px"
                large
                :disabled="disabledRule(button) || button.disabled"
                @click.stop="buttonAction(button.text)"
                @mouseover="button.hover = true"
                @mouseleave="button.hover = false"
              >
                <v-icon
                  v-if="button.hover"
                  left
                  large
                  class="grow ml-1"
                  style="position: absolute; left: 0"
                >
                  {{ button.plusIcon }}
                </v-icon>
                <v-icon
                  v-else
                  left
                  :large="!$vuetify.breakpoint.mobile"
                  :size="$vuetify.breakpoint.mobile ? '28' : 'large'"
                  transition="scale-transition"
                  class="ml-1"
                  style="position: absolute; left: 0"
                >
                  {{ button.icon }}
                </v-icon>
                <span
                  class="text-center"
                  style="position: absolute; font-size: 11px !important;"
                >
                  {{ convertGrammatically($t("actions|new"), button.text) }}<br><span style="font-size: 14px">{{ button.text }}</span>
                </span>
              </v-btn>
            </div>
          </template>
          <span>{{ generateMessage(button) }}</span>
        </v-tooltip>
      </v-col>
      <AddMember />
    </v-row>
    <v-row
      v-else
      align="center"
      justify="center"
    >
      <v-col
        v-for="(button, index) in clientButtons"
        :key="index"
        cols="4"
      >
        <v-tooltip
          top
          color="warning"
          max-width="250"
        >
          <template v-slot:activator="{ on }">
            <div v-on="disabledClientButtonRule(button) ? on : ''">
              <v-btn
                color="primary"
                width="90%"
                min-height="50px"
                large
                :disabled="disabledClientButtonRule(button)"
                @click.stop="buttonAction(button.text)"
                @mouseover="button.hover = true"
                @mouseleave="button.hover = false"
              >
                <v-icon
                  v-if="button.hover"
                  left
                  large
                  class="grow ml-1"
                  style="position: absolute; left: 0"
                >
                  {{ button.plusIcon }}
                </v-icon>
                <v-icon
                  v-else
                  left
                  large
                  transition="scale-transition"
                  class="ml-1"
                  style="position: absolute; left: 0"
                >
                  {{ button.icon }}
                </v-icon>
                <span
                  class="text-center"
                  style="position: absolute; font-size: 9px"
                >
                  {{ $t("actions|new") }}<br><span style="font-size: 14px">{{ button.text }}</span>
                </span>
              </v-btn>
            </div>
          </template>
          <span>{{ generateMessage(button) }}</span>
        </v-tooltip>
      </v-col>
      <AddProfessional
        ref="AddProfessional"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { userHasAccess } from '@/utils/utils'
import { mdiAccountTie } from '@mdi/js'
import AddProfessional from '../../components/dialogs/AddProfessional.vue'
import AddMember from '../../components/dialogs/AddMember.vue'

export default {
  components: {
    AddMember,
    AddProfessional
  },
  props: {
    isNotes: {
      type: Boolean,
      default: false
    },
    isQuickstart: {
      type: Boolean,
      default: false
    },
    isPinboard: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hoverNewClient: false,
      hoverNewProfessional: false,
      hoverNewCase: false,
      hoverNewTemplate: false,
      hoverNewDocument: false,
      hoverNewMember: false,
      hoverNewCaseModel: false,
      hover: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      actualLanguage: state => state.account.actualLanguage
    }),
    buttons () {
      return [
        {
          text: this.$t('clients|client'),
          hover: this.hoverNewClient,
          plusIcon: 'mdi-plus-circle-outline',
          icon: 'mdi-clipboard-account-outline',
          disabled: !userHasAccess(this.account, this.company, null, null, 'QuickStart')
        },
        {
          text: this.$t('cases|case'),
          hover: this.hoverNewCase,
          plusIcon: 'mdi-plus-circle-outline',
          icon: 'mdi-briefcase',
          disabled: !userHasAccess(this.account, this.company, null, null, 'QuickStart')
        },
        {
          text: this.$t('case_models|case_model'),
          hover: this.hoverNewCaseModel,
          plusIcon: 'mdi-plus-circle-outline',
          icon: 'mdi-briefcase-plus-outline',
          disabled: !userHasAccess(this.account, this.company, null, null, 'QuickStart')
        },
        {
          text: this.$t('templates|template'),
          hover: this.hoverNewTemplate,
          plusIcon: 'mdi-plus-circle-outline',
          icon: 'mdi-file-hidden',
          disabled: !userHasAccess(this.account, this.company, null, null, 'QuickStart')
        },
        {
          text: this.$t('documents|document'),
          hover: this.hoverNewDocument,
          plusIcon: 'mdi-plus-circle-outline',
          icon: 'mdi-file',
          disabled: !userHasAccess(this.account, this.company, null, null, 'QuickStart')
        },
        {
          text: this.$t('members|member'),
          hover: this.hoverNewMember,
          plusIcon: 'mdi-plus-circle-outline',
          icon: 'mdi-account-supervisor'
        }
      ]
    },
    clientButtons () {
      return [
        {
          text: this.$t('common|professional'),
          hover: this.hoverNewProfessional,
          plusIcon: 'mdi-plus-circle-outline',
          icon: mdiAccountTie
        },
        {
          text: this.$t('cases|case'),
          hover: this.hoverNewCase,
          plusIcon: 'mdi-plus-circle-outline',
          icon: 'mdi-briefcase'
        },
        {
          text: this.$t('documents|document'),
          hover: this.hoverNewDocument,
          plusIcon: 'mdi-plus-circle-outline',
          icon: 'mdi-file'
        }
      ]
    }
  },
  methods: {
    buttonAction (text) {
      if (text === this.$t('clients|client')) {
        this.addClientAction()
      } else if (text === this.$t('cases|case')) {
        this.addCaseAction()
      } else if (text === this.$t('case_models|case_model')) {
        this.addCaseFormAction()
      } else if (text === this.$t('templates|template')) {
        this.addTemplateAction()
      } else if (text === this.$t('documents|document')) {
        this.addDocumentAction()
      } else if (text === this.$t('members|member')) {
        this.addMemberAction()
      } else if (text === this.$t('common|professional')) {
        this.addProfessionalAction()
      }
    },
    convertGrammatically (_new, text) {
      const actualLanguage = this.actualLanguage
      if (actualLanguage === 'en') return _new
      if (actualLanguage === 'fr') return _new
      if (actualLanguage === 'bg') {
        if (text === 'Дело') return 'добави ново'
        else return _new
      }
      if (actualLanguage === 'de') {
        if (text === 'Kunde' || text === 'Fall') return _new
        if (text === 'Dokument' || text === 'Mitglied' || text === 'Fallmodell') return 'neues'
        if (text === 'Vorlage') return 'neue'
      }
      if (actualLanguage === 'es') {
        if (text === 'Plantilla') return 'Añadir nueva'
        else return _new
      }
      if (actualLanguage === 'it') return _new
      if (actualLanguage === 'ro') return _new
    },
    disabledRule (button) {
      if (button.text === this.$t('members|member') && this.account && !this.account.companyId) {
        return true
      }
      if (this.account && this.account.accountData && !this.account.accountData.requiredFieldsFilled &&
      (button.text === this.$t('cases|case') || button.text === this.$t('clients|client'))) {
        return true
      } else {
        return false
      }
    },
    disabledClientButtonRule (button) {
      if (this.account && this.account.accountData && !this.account.accountData.requiredFieldsFilled &&
      (button.text === this.$t('cases|case') || button.text === 'professional')) {
        return true
      } else {
        return false
      }
    },
    generateMessage (button) {
      if (button.text === this.$t('clients|client') || button.text === this.$t('cases|case')) return this.$t('action|activate_button')
      if (button.text === this.$t('members|member')) return this.$t('action|activate_collaboration')
      if (button.text === 'professional') return this.$t('action|activate_button')
    },
    addClientAction () {
      if (this.$vuetify.breakpoint.mobile) {
        this.$router.push({
          name: 'AddClientMobile'
        })
      } else EventBus.$emit('dialog-add-client', {})
    },
    addCaseAction () {
      EventBus.$emit('dialog-add-case', {})
    },
    addCaseFormAction () {
      EventBus.$emit('open-new-case-model-dialog')
    },
    addTemplateAction () {
      EventBus.$emit('dialog-add-template', 'fromDashboard')
    },
    addDocumentAction () {
      EventBus.$emit('dialog-add-document', {})
    },
    addMemberAction () {
      EventBus.$emit('dialog-add-member', {})
    },
    addProfessionalAction () {
      this.$refs.AddProfessional.dialog = true
    }
  }
}
</script>

<style scoped>
.icon {
  position: absolute;
  left: 0;
}
.grow {
  transition: all .2s ease-in-out;
}
.v-btn:hover .grow,
.v-btn:hover .grow {
  transform: scale(1.2);
  transition: all .3s ease-in-out;
}
</style>
