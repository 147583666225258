import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      clientsLoading: state => state.clients.loading,
      accountLoading: state => state.account.loading
    })
  },
  methods: {
    ...mapActions({
      fetchClient: 'clients/fetchClient',
      addClient: 'clients/addClient',
      updateClient: 'clients/updateClient',
      uploadDocument: 'documents/uploadDocument',
      addToast: 'toasts/addToast'
    }),
    async submit ({ form = {}, files = [], actualTab = {} } = {}) {
      if (!this.account.companyId) actualTab.isMyTab = true
      try {
        let data
        if (this.actionType === 'create') {
          if (actualTab.isMyTab) {
            data = await this.addClient({
              clientType: this.clientType,
              clientData: {
                ...form
              },
              isMyPersonalClient: true
            })
          }
          if (actualTab.isCompanyTab) {
            data = await this.addClient({
              clientType: this.clientType,
              clientData: {
                ...form
              },
              isCompanyClient: true,
              companyId: this.company._id
            })
          }
          if (actualTab.isGroupTab) {
            data = await this.addClient({
              clientType: this.clientType,
              clientData: {
                ...form
              },
              isGroupClient: true,
              groupId: actualTab.group._id
            })
          }
        } else if (this.actionType === 'update') {
          data = await this.updateClient({
            _id: this.client._id,
            payload: {
              payload: {
                clientData: {
                  ...form
                }
              }
            }
          })
        }
        files = files.filter(f => !f._id)
        for (let i = 0; i < files.length; i++) {
          files[i].loading = true
          const clientId = data.data._id
          const fileLanguage = files[i].language
          const file = files[i].file
          const payload = {
            clientId,
            language: fileLanguage,
            file
          }
          await this.uploadDocument(payload)
          files[i].loading = false
        }
        this.error = null
        if (this.actionType === 'create') {
          this.dialog = false
        }
        if (this.actionType === 'update') {
          this.individualDialog = false
          this.companyDialog = false
        }
        this.addToast({
          title: this.$t('expressions|client_profile_updated_successfuly'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        console.error(e, 'error')
        this.error = e
      }
    }
  }
}
