<template>
  <v-dialog
    v-if="dialog && company"
    v-model="dialog"
    keydown
    width="800px"
    transition="dialog-bottom-transition"
  >
    <v-container
      fill-height
      fluid
      class="pa-0"
    >
      <v-card
        class="px-2"
        width="100%"
      >
        <v-toolbar
          flat
          class="general-form-toolbar"
          width="100%"
          :rounded="false"
        >
          <v-toolbar-title>{{ $t('members|new_member') }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <div
              style="position: absolute; top: 3px; right: 7px"
            >
              <v-btn
                icon
                @click="close()"
                @mouseover="hoverCloseButtonIcon = true"
                @mouseleave="hoverCloseButtonIcon = false"
              >
                <v-icon
                  :small="hoverCloseButtonIcon"
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :value="getValueProgress(company, company.members.length + memberFields.length)"
            height="40"
            class="mt-n4 mb-6 rounded"
          >
            <template v-slot:default="{ value }">
              <strong
                class="white--text ml-4"
              >
                {{ company.members.length + memberFields.length + '/' + company.seats + ' ' + $t('members|members') }}
              </strong>
            </template>
          </v-progress-linear>
          <v-row
            v-for="(input, index) in memberFields"
            :key="`memberInput-${index}`"
            align="center"
          >
            <v-col cols="8">
              <v-text-field
                v-model="input.email"
                outlined
                dense
                :label="$t('common|email')"
                :hint="getTheRightHint(index, memberFields)"
                persistent-hint
                :rules="[(v) => !!v || $t('common|field_required')]"
                required
                clearable
                @input="checkEmailExistence(index, memberFields)"
              />
            </v-col>
            <v-col cols="4">
              <div>
                <v-select
                  v-model="input.userRole"
                  :items="userRoles"
                  item-text="text"
                  item-value="role"
                  :label="$t('members|user_role')"
                  :hint="$t('common|field_required')"
                  persistent-hint
                  :append-outer-icon="memberFields.length > 1 ? 'mdi-trash-can' : ''"
                  :rules="[(v) => !!v || $t('common|field_required')]"
                  dense
                  outlined
                  @click:append-outer="removeField(index, memberFields)"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            rounded
            outlined
            small
            :disabled="disableIfNoSeatsLeft()"
            color="primary"
            class="mb-2"
            @click="addField()"
          >
            <v-icon
              small
              class="mr-1"
            >
              mdi-account-plus
            </v-icon>
            {{ $t('members|invite_more_members') }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            :disabled="!hasPermission"
            color="primary"
            rounded
            min-width="150"
            @click="addNewMember"
          >
            {{ $t('actions|submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import axios from '@/plugins/axios'

export default {
  data () {
    return {
      dialog: false,
      accounts: [],
      loading: false,
      error: null,
      hoverCloseButtonIcon: false,
      memberFields: [],
      userRoles: [
        {
          role: 'Regular',
          text: this.$t('members|regular')
        },
        {
          role: 'Advanced',
          text: this.$t('members|advanced')
        },
        {
          role: 'Admin',
          text: this.$t('members|admin')
        }
      ],
      hasPermission: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    })
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.close()
      } else {
        this.addField()
      }
    },
    memberFields: {
      handler (value) {
        if (!value) {
          this.hasPermission = false
        }
        if (!value.length) {
          this.hasPermission = false
        }
        this.hasPermission =
        this.memberFields.length !== 0 &&
        this.memberFields.every(f => f.email !== null && (f.email.length > 0 && f.userRole) && f.verifiedEmail === 'email_exists')

        const duplicates = this.memberFields
          .map((el, i) => {
            return this.memberFields.find((element, index) => {
              if (i !== index && element.email && element.email.length && element.email === el.email) {
                return el
              }
            })
          })
          .filter(Boolean)
        if (duplicates.length) {
          this.hasPermission = false
          this.addToast({
            title: this.$t('expressions|duplicate_emails'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      },
      deep: true
    }
  },
  created () {
    EventBus.$on('dialog-add-member', this.onAddMemberEvent)
  },
  beforeDestroy () {
    EventBus.$off('dialog-add-member', this.onAddMemberEvent)
  },
  methods: {
    ...mapActions({
      addMember: 'company/addMember',
      addToast: 'toasts/addToast'
    }),
    onAddMemberEvent (event) {
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.loading = false
      this.memberFields = []
      this.error = null
      this.hasPermission = false
      this.hoverCloseButtonIcon = false
    },
    addField () {
      this.memberFields.push({
        email: '',
        userRole: '',
        verifiedEmail: 'not_tested'
      })
    },
    removeField (index) {
      this.memberFields.splice(index, 1)
    },
    disableIfNoSeatsLeft () {
      const actualUsers = this.company.members.length + this.memberFields.length
      const availableSeats = this.company.seats
      if (availableSeats - actualUsers >= 1) return false
      else return true
    },
    getValueProgress (company, membersAmount) {
      return membersAmount / company.seats * 100
    },
    getTheRightHint (index, memberFields) {
      if (memberFields[index].verifiedEmail === 'not_tested') {
        return this.$t('expressions|enter_email_of_existing_lavvira_user')
      } else if (memberFields[index].verifiedEmail === 'email_exists') {
        return this.$t('common|email_exists')
      } else if (memberFields[index].verifiedEmail === 'email_does_not_exist') {
        return this.$t('common|email_does_not_exist')
      } else if (memberFields[index].verifiedEmail === 'user_has_already_been_invited') {
        return this.$t('expressions|user_has_already_been_invited')
      } else if (memberFields[index].verifiedEmail === 'participates_in_other_company') {
        return this.$t('expressions|user_participates_in_other_company')
      }
    },
    async checkEmailExistence (index, memberFields) {
      const emailToBeChecked = memberFields[index].email
      const payload = {
        email: emailToBeChecked
      }
      try {
        const { data } = await axios.post(`/emailExistenceCheck`, payload)
        if (data.success) {
          memberFields[index].verifiedEmail = 'email_exists'
        } else if (!data.success && data.msg && data.msg === 'already_invited') {
          memberFields[index].verifiedEmail = 'user_has_already_been_invited'
        } else if (!data.success && data.msg && data.msg === 'participates_in_other_company') {
          memberFields[index].verifiedEmail = 'participates_in_other_company'
        } else {
          memberFields[index].verifiedEmail = 'email_does_not_exist'
        }
      } catch (error) {
        console.error(error, 'email check')
      }
    },
    async addNewMember () {
      this.loading = true
      const _id = this.company._id
      const payload = {
        memberData: this.memberFields
      }
      await this.addMember({ _id, payload })
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
  .general-form {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .general-form-toolbar {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 50;
  }
</style>
